<template>
  <v-container>
    <go-back-btn :routeToGo="routeToGo" />
    <page-header class="mb-3" :title="title" />
    <v-data-table
      :headers="headers"
      :items="clientesPorFecha"
      :single-expand="singleExpand"
      :search="search"
      :footer-props="{ 'items-per-page-text': 'Página' }"
      :expanded.sync="expanded"
      show-expand
      item-key="clienteId"
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td v-if="item.mostrarScript == true" :colspan="headers.length">
          Script: {{ item.datosComplementarios && item.datosComplementarios.script !== null ? item.datosComplementarios.script : "N/A" }}
        </td>
        <td v-else :colspan="headers.length">
          No hay script de {{ item.nombre }}.
        </td>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-col cols="10">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <!-- <v-spacer></v-spacer> -->
        </v-toolbar>
      </template>
    </v-data-table>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" align="right">
        <v-tooltip top max-width="35%">
          <template v-slot:activator="{ attrs }">
            <v-btn
              color="primary"
              class="mt-2 py-4 to-right fontsize"
              align="end"
              v-bind="attrs"
              outlined
              small
              :disabled="clientesPorFecha.length == 0"
              @click="exportExcel()"
            >
              Exportar lista completa
            </v-btn>
          </template>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import { mask } from "vue-the-mask";
import enums from "../utils/enums/index.js";
import helpersExcel from "@/utils/helpers/ImportExportExcel";

export default {
  components: { PageHeader, GoBackBtn },
  directives: { mask },
  name: "ClientesXFechaCorte",

  data() {
    return {
      isLoading: false,
      dialog: false,
      expanded: [],
      clientesPorFecha: [],
      search: "",
      routeToGo: "/licencias-clientes/fechas-corte",
      singleExpand: true,
      date: null,
      dateFormatted: null,
      copyIcon: enums.icons.COPY,
      menu: false,
      regClientes: null,
      fechaCor: null,
      copied: false,
      title: "Clientes por fecha de corte",
      headers: [
        {
          text: "Nombre cliente",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Fecha corte", value: "fechaCorte", sortable: true },
        { text: "Ultima conexión", value: "ultimaConexion", sortable: true },
        { text: "Nueva fecha corte", value: "nuevaFechaCorte", sortable: true },
      ],
      clienteId: null,
      openModalScript: false,
      fechaFormateada: null,
      objectRegistros: {},
    };
  },
  computed: {
    ...mapGetters({
      alert: "alert",
    }),
  },
  created() {
    this.regClientes = this.$route.params.itemsCantClientes;
    this.fechaCor = this.regClientes.fechaCorte;
    // Reformatear la cadena a un formato reconocido (DD/MM/YYYY a MM/DD/YYYY)
    const partesFecha = this.fechaCor.split("/");
    const fechaReformateada = `${partesFecha[1]}/${partesFecha[0]}/${partesFecha[2]}`;
    // Crear el objeto de fecha
    const fecha = new Date(fechaReformateada);
    // Obtener las partes de la fecha y la hora
    const anio = fecha.getFullYear();
    const mes = String(fecha.getMonth() + 1).padStart(2, "0");
    const dia = String(fecha.getDate()).padStart(2, "0");
    const horas = String(fecha.getHours()).padStart(2, "0");
    const minutos = String(fecha.getMinutes()).padStart(2, "0");
    const segundos = String(fecha.getSeconds()).padStart(2, "0");
    this.fechaFormateada = `${anio}-${mes}-${dia}T${horas}:${minutos}:${segundos}`;
    this.loadClientesXFecha();
  },

  methods: {
    ...mapActions({
      getClienteFecha: "clientesData/getClienteFecha",
      getScript: "clientesData/getScript",
      setAlert: "setAlert",
    }),
    async loadClientesXFecha() {
      this.isLoading = true;
      const response = await this.getClienteFecha({
        fechaCorte: this.fechaFormateada,
      });
      this.clientesPorFecha = response.data.data;
      this.isLoading = false;
    },
    exportExcel() {
      let result = [];
      this.clientesPorFecha.forEach((x) =>
        result.push({
          ["Nombre cliente"]: x.nombre,
          ["Fecha corte"]: x.fechaCorte,
          ["Ultima conexión"]: x.ultimaConexion,
          ["Nueva fecha corte"]: x.nuevaFechaCorte,
          ["Script"]: x.datosComplementarios
            ? x.datosComplementarios.script
            : "N/A",
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(
        formato,
        "Lista completa de clientes por fecha de corte"
      );
    },
  },
};
</script>
<style scoped>
::v-deep .myLabel {
  color: white;
}
</style>
