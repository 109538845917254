import * as XLSX from "xlsx";

import store from "@/store";

const helperExcel = {
  excelImport(file, rowsToIgnore) {
    const types = file.name.split(".")[1];
    const fileType = ["xlsx", "xlc", "xlm", "xls", "xlt", "xlw", "csv"].some(
      (item) => item == types
    );
    if (!fileType) {
      throw "¡Error de formato! Vuelva a seleccionar";
    }
    let result = [];
    var reader = new FileReader();
    reader.onload = () => {
      //JSON.stringify( )
      var fileData = reader.result;
      // console.log("fileData", fileData);
      var wb = XLSX.read(fileData, { type: "binary" });

      wb.SheetNames.forEach((sheetName) => {
        let workBookSheet = wb.Sheets[sheetName];

        // Define el rango que va a tomar en la sheet (hoja)
        let range = XLSX.utils.decode_range(workBookSheet["!ref"]);
        range.s.r = 0 + rowsToIgnore; // <-- zero-indexed, so setting to 1 will skip row 0
        workBookSheet["!ref"] = XLSX.utils.encode_range(range);

        result.push({
          hoja: sheetName,
          filas: XLSX.utils.sheet_to_json(workBookSheet),
        });
      });
    };
    reader.readAsBinaryString(file);
    return result;
  },

  excelExport(jsonArray, fileName) {
    // export json to Worksheet of Excel
    var objFilas = XLSX.utils.json_to_sheet(jsonArray.filas);

    // // A workbook is the name given to an Excel file
    var wb = XLSX.utils.book_new(); // make Workbook of Excel
    // // add Worksheet to Workbook
    // // Workbook contains one or more worksheets
    XLSX.utils.book_append_sheet(wb, objFilas, jsonArray.hoja); // sheetAName is name of Worksheet

    // Calcular el ancho máximo de cada columna
    var encabezados = Object.keys(jsonArray.filas[0]);
    var columnWidths = [];
    encabezados.forEach((encabezado, index) => {
      var longitudMaxima = encabezado.length;
      jsonArray.filas.forEach((fila) => {
        var valor = fila[encabezado];
        if (
          valor &&
          typeof valor === "string" &&
          valor.length > longitudMaxima
        ) {
          longitudMaxima = valor.length;
        }
      });
      columnWidths[index] = { wch: longitudMaxima + 2 }; // Agregar un margen adicional
    });

    // Establecer el ancho de las columnas
    objFilas["!cols"] = columnWidths;
    // export Excel file

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  },
  translateDate(dateNumber) {
    // si el length es de 10, significa que la fecha esta bien construida
    if (dateNumber == undefined) return "";
    if (dateNumber?.length == 10) return dateNumber;
    let dateParsed = XLSX.SSF.parse_date_code(dateNumber);
    var finalDate = new Date();
    if (dateParsed == null) return "";
    let tryParse = isNaN(parseInt(dateNumber));
    if (tryParse) return dateNumber;
    finalDate.setUTCDate(dateParsed.d);
    finalDate.setUTCMonth(dateParsed.m - 1);
    finalDate.setUTCFullYear(dateParsed.y);
    finalDate.setUTCHours(dateParsed.H);
    finalDate.setUTCMinutes(dateParsed.M);
    finalDate.setUTCSeconds(dateParsed.S);
    var finalDateISOS = finalDate.toISOString();
    const [year, month, day] = finalDateISOS.substring(0, 10).split("-");
    return `${day}/${month}/${year}`;
  },
  async validarCabecerasExcel(file, expectedHeaders) {
    // file -> v-model del v-file-input a validar
    // expectedHeaders -> Array de headers esperados. ej: ["Header 1", "Header 2"]
    // false cuando encuentra un error al leer archivo, no coincide el length de headers esperados y recibidos o no coinciden los valores
    // true cuando coincide length y valores
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];

        const headers = [];
        const range = XLSX.utils.decode_range(sheet["!ref"]);
        const cols = range.e.c + 1;

        for (let i = 0; i < cols; i++) {
          const cell = sheet[XLSX.utils.encode_cell({ r: 0, c: i })];
          if (!cell || !cell.v) {
            break;
          }
          headers.push(cell.v);
        }

        if (headers.length < expectedHeaders.length) {
          store.commit(
            "user/setAlertMessage",
            {
              type: "warning",
              message: "La cantidad de cabeceras es menor a la esperada",
            },
            { root: true }
          );
          resolve(false);
        } else if (!expectedHeaders.every((valor) => headers.includes(valor))) {
          store.commit(
            "user/setAlertMessage",
            {
              type: "warning",
              message:
                "Formato de cabeceras inválido, consulte el formato ejemplo.",
            },
            { root: true }
          );
          resolve(false);
        }
        resolve(true);
      };

      // reader.onerror = (event) => {
      //   resolve(false);
      // };

      reader.readAsArrayBuffer(file);
    });
  },
};

export default helperExcel;
